import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MerchantLayout from 'src/components/Layouts/MerchantLayout';
import UserGroups from '../layout';
import useRedux from 'src/redux/useRedux';
import {
  getDeliverySetting,
  DeliveryData,
  deleteDelivery,
} from 'src/redux/deliverySettings';
import PopUp from 'src/components/Modal/modal';
import { NormalText } from 'src/components/styled/text.styled';
import Confirmation from 'src/components/Modal/confirmation';
import Tables from 'src/components/Table/tableData';
import { Button as ButtonMUI, Grid, IconButton, Box } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import checkPermission from 'src/helper/checkPermission';
import { formatCurrency } from 'src/helper/generalFunction';
import ConfirmationModal from 'src/components/Modal/confirmation';

const DeliveryPage = () => {
  const navigate = useNavigate();
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [data, setData] = useState<DeliveryData[]>();
  const [changeStatus, setChangeStatus] = useState(false);

  const columns = [
    {
      name: 'Nama Toko',
      selector: (row: any) => {
        return (
          <div>
            <NormalText fontSize={'12px'} margin={'0px 0px 0px 0px'}>
              {row.store.storeName ? row.store.storeName : '-'}
            </NormalText>
          </div>
        );
      },
      sortable: true,
      center: true,
    },
    {
      name: 'Max. Jangkauan',
      selector: (row: any) => {
        return (
          <NormalText margin={'0px 0px 0px 0px'} fontSize={'12px'}>
            {row.maxRangeDelivery ? `${row.maxRangeDelivery} KM` : ''}
          </NormalText>
        );
      },
      sortable: true,
      center: true,
    },
    {
      name: 'Metode Perhitungan',

      selector: (row: any) => {
        return (
          <NormalText margin={'0px 0px 0px 0px'} fontSize={'12px'}>
            {row.calculateMethod === 'tarif_km' ? `Tarif Per KM` : row.calculateMethod === 'tarif_flat' ? 'Tarif Flat' : ''}
          </NormalText>
        );
      },
      sortable: true,
      center: true,
    },
    {
      name: 'Ongkos Kirim',
      selector: (row: any) => {
        return (
          <NormalText margin={'0px 0px 0px 0px'} fontSize={'12px'}>
           {row.calculateMethod === 'tarif_km' ? `${formatCurrency(row.kmPriceValue)} (Per KM)` : row.calculateMethod === 'tarif_flat' ? `${formatCurrency(row.deliverFee)} (Flat)` : '' }
          </NormalText>
        );
      },
      sortable: true,
      center: true,
    },
    {
      name: 'Tarif Dasar',
      selector: (row: any) => {
        return (
          <NormalText margin={'0px 0px 0px 0px'} fontSize={'12px'}>
            {row.enableFlatPrice ? `${formatCurrency(row.flatPriceValue)}` : '-' }
          </NormalText>
        );
      },
      sortable: true,
      center: true,
    },
    {
      name: 'Jangkauan Tarif Dasar',
      selector: (row: any) => {
        return (
          <NormalText margin={'0px 0px 0px 0px'} fontSize={'12px'}>
           {row.maxRangeFlatPrice ? `${row.maxRangeFlatPrice} KM` : '-'}
          </NormalText>
        );
      },
      sortable: true,
      center: true,
    },
    {
      name: 'Aksi',
      center: true,
      cell: (row: any) => {
        return (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {checkPermission('Delivery Setting', 'is_edit') && (
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => {
                  navigate('/setting/deliverysetting/edit', {
                    state: { data: row, statusUrl: 'edit' },
                  });
                }}
              >
                <Edit />
              </IconButton>
            )}
            {checkPermission('Delivery Setting', 'is_delete') && (
              <IconButton
                aria-label="delete"
                color="error"
                onClick={() => {
                  setDeleteModal(true)
                  setDeleteId(row.id)
                }}
              >
                <Delete />
              </IconButton>
            )}
          </Grid>
        );
      },
    },
  ];

  const [openModal, setOpenModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [statusModal, setStatusModal] = useState('');
  const [orderNo, setOrderNo] = useState('');
  const {
    thunkDispatch,
    storeState: { Auth, AppSetting, DeliverySetting },
  } = useRedux();

  const getAll = () => {
    let dataObject = {
      offset: offset - 1,
      limit: limit,
      filter: filter,
    };
    thunkDispatch(getDeliverySetting(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status == 'success') {
          console.log(res.data.data);
          if (res.data.data && res.data.data.length !== 0) {
            setData(res.data.data);
          }
          setTotalData(
            res.data && res.data.meta && res.data.meta.total
              ? res.data.meta.total
              : 0,
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  React.useEffect(() => {
    getAll();
  }, []);

  const deletDelivery = (idDelivery: any) => {
    let dataObject = {
      id: idDelivery ?? '',
    };
    thunkDispatch(deleteDelivery(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status == 'success') {
          setLoading(false);
          getAll()
          setDeleteModal(false)
          setOpenModal(true);
          setStatusModal('success');
        } else {
          setLoading(false);
          setOpenModal(true);
          setStatusModal('failed');
        }
      })
      .catch((err) => {
        setLoading(false);
        setStatusModal('failed');
        setOpenModal(true);
        console.error(err);
      });
    setLoading(false);
  };

  return (
    <MerchantLayout>
      <UserGroups tabActive={3}>
        <ConfirmationModal open={deleteModal} title={'Konfirmasi'} description={'Apakah anda yakin akan menghapus data'} handleConfirm={() => deletDelivery(deleteId)} handleClose={() => setDeleteModal(false)} />
        <PopUp open={openModal} handleClose={() => setOpenModal(false)}>
          <NormalText
            textAlign="center"
            fontWeight={'bold'}
            fontSize={'20px'}
            margin={'0 0 20px 0'}
          >
            {statusModal === 'success' ? 'Sukses' : 'Gagal'}
          </NormalText>
          <NormalText
            textAlign="center"
            fontSize={'14px'}
            margin={'0 0 20px 0'}
          >
            {statusModal === 'success' ? (
              <span>Berhasil menghapus data loyalti</span>
            ) : DeliverySetting?.error?.response?.data?.message ? (
              <span>{DeliverySetting.error.response.data.message}</span>
            ) : (
              <span>
                Gagal menghapus loyalti, <br /> silahkan periksa kembali data
                anda
              </span>
            )}
          </NormalText>
          <Box textAlign={'center'} margin={'20px 0 0 0 '}>
            <ButtonMUI
              variant="contained"
              onClick={() => setOpenModal(false)}
              sx={{ width: 100, height: 40, marginRight: 3 }}
              color="error"
            >
              Oke
            </ButtonMUI>
          </Box>
        </PopUp>
        <Tables
          records={data}
          handleAddButton={() =>
            navigate('/setting/deliverysetting/create', {
              state: { data: null, statusUrl: 'add' },
            })
          }
          columns={columns}
          title="Pengaturan Pengiriman"
          isSearch={true}
          addButton={
            checkPermission('Delivery Setting', 'is_add') ? true : false
          }
          titleAdd="Tambah Pengiriman"
          onChangeSearch={(e) => setFilter(e.target.value)}
          valueSearch={filter}
          progressPending={loading}
          paginationPerPage={limit}
          totalRow={totalData}
          onChangePage={(page) => setOffset(page)}
          onChangeRowsPerPage={(currentRowsPerPage) =>
            setLimit(currentRowsPerPage)
          }
        />
      </UserGroups>
    </MerchantLayout>
  );
};

export default DeliveryPage;
