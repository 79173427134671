import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  DeliveryState,
  CreateDeliveryTypes,
  EditDeliveryTypes,
  DeleteDeliveryProps,
} from "./types";
import ApiService from "src/services/ApiService";
import axios from "axios";
import { getToken } from "src/helper/generalFunction";

export interface ListProps {
  offset?: number;
  limit?: number;
}

export const getDeliverySetting = createAsyncThunk(
  "deliverySettings/get",
  async (props: ListProps) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_URL}/delivery-setting?limit=${
          props.limit ?? 0
        }&offset=${props.offset ?? 0}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as DeliveryState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as DeliveryState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as DeliveryState;
    }
  }
);

export const createDeliverySetting = createAsyncThunk(
  "deliverySettings/post",
  async (props: CreateDeliveryTypes) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/delivery-setting",
        props,
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY,
            token: getToken(),
          },
        }
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as DeliveryState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as DeliveryState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as DeliveryState;
    }
  }
);

export const editDelivery = createAsyncThunk(
  "deliverySettings/put",
  async (props: EditDeliveryTypes) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/delivery-setting",
        props,
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY,
            token: getToken(),
          },
        }
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as DeliveryState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as DeliveryState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as DeliveryState;
    }
  }
);

export const deleteDelivery = createAsyncThunk(
  "deliverySettings/delete",
  async (props: DeleteDeliveryProps) => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_API_URL +
          `/merchant/delivery/point/${props.id}/delete/setting`,
        {
          headers: {
            api_key: process.env.REACT_APP_API_KEY,
            token: getToken(),
          },
        }
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as DeliveryState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as DeliveryState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as DeliveryState;
    }
  }
);
