import IcDashboard from "../../assets/icons/dashboard.svg";
import IcInfo from "../../assets/icons/information.svg";
import IcOrder from "../../assets/icons/order.svg";
import IcProduct from "../../assets/icons/product.svg";
import IcReport from "../../assets/icons/report.svg";
import IcSetting from "../../assets/icons/settings.svg";
import IcStore from "../../assets/icons/store.svg";
import IcUser from "../../assets/icons/user.svg";
export interface MenuTypes {
  id: string;
  type: string;
  path: string;
  permission: string[];
  text: string;
  children?: Children[];
  icon?: any;
}

interface Children {
  id: string;
  type: string;
  path: string;
  permission: string[];
  text: string;
  children?: any;
}

const features: MenuTypes[] = [
  {
    id: "dashboard",
    text: "Dasbor",
    path: "/",
    type: "item",
    permission: ["Dashboard Merchant@is_view"],
    icon: IcDashboard,
  },
  {
    id: "informasi",
    text: "Informasi",
    path: "#",
    type: "collapse",
    permission: ["Banner@is_view"],
    icon: IcInfo,
    children: [
      {
        id: "banner",
        text: "Banner",
        path: "/informasi/banner",
        type: "item",
        permission: ["Banner@is_view"],
      },
    ],
  },
  {
    id: "kasir",
    text: "Kasir",
    path: "#",
    type: "collapse",
    permission: ["Cashier History@is_view"],
    icon: IcInfo,
    children: [
      {
        id: "sesikasir",
        text: "Sesi Kasir",
        path: "/kasir/sesi-kasir",
        type: "item",
        permission: ["Cashier History@is_view"],
      },
    ],
  },
  {
    id: "customer",
    text: "Customer",
    path: "#",
    type: "collapse",
    permission: ["Customer@is_view"],
    icon: IcUser,
    children: [
      {
        id: "customerlist",
        text: "Daftar Customer",
        path: "/customer/list",
        type: "item",
        permission: ["Customer@is_view"],
      },
    ],
  },
  {
    id: "product",
    text: "Produk",
    path: "#",
    type: "collapse",
    permission: [
      "Product@is_view",
      "Brand@is_view",
      "Category@is_view",
      "Product Store@is_view",
      "Product Add On@is_view",
      "Voucher@is_view",
    ],
    icon: IcProduct,
    children: [
      {
        id: "products",
        text: "Daftar Produk",
        path: "/products/product/list",
        type: "item",
        permission: ["Product@is_view"],
      },
      {
        id: "brands",
        text: "Brands",
        path: "/products/brands",
        type: "item",
        permission: ["Brand@is_view"],
      },
      {
        id: "category",
        text: "Kategori",
        path: "/products/categories",
        type: "item",
        permission: ["Category@is_view"],
      },
      {
        id: "productsstrore",
        text: "Produk Toko",
        path: "/products/store-product",
        type: "item",
        permission: ["Product Store@is_view"],
      },
      {
        id: "productaddon",
        text: "Produk Add-On",
        path: "/products/product-addon",
        type: "item",
        permission: ["Product Add On@is_view"],
      },
      {
        id: "voucher",
        text: "Voucher",
        path: "/products/vouchers",
        type: "item",
        permission: ["Voucher@is_view"],
      },
    ],
  },
  {
    id: "storesgroup",
    text: "Toko",
    path: "#",
    type: "collapse",
    permission: [
      "Store@is_view",
      "Pickup Point@is_view",
      "Pickup Point Store@is_view",
    ],
    icon: IcStore,
    children: [
      {
        id: "storelist",
        text: "Daftar Toko",
        path: "/stores/store/list",
        type: "item",
        permission: ["Store@is_view"],
      },
      {
        id: "tabletype",
        text: "Tipe Meja",
        path: "/stores/tabletype/list",
        type: "item",
        permission: ["Table Type@is_view"],
      },
      {
        id: "table",
        text: "Meja",
        path: "/stores/table/list",
        type: "item",
        permission: ["Table Merchant@is_view"],
      },
      {
        id: "pickuppoint",
        text: "Pickup Point",
        path: "/stores/pickup-point-list/list",
        type: "item",
        permission: ["Pickup Point@is_view"],
      },
      {
        id: "pupsetup",
        text: "Pickup Point Setup",
        path: "/stores/pickup-point-setup/list",
        type: "item",
        permission: ["Pickup Point Store@is_view"],
      },
    ],
  },
  {
    id: "orders",
    text: "Penjualan",
    path: "#",
    type: "collapse",
    permission: ["Order@is_view"],
    icon: IcOrder,
    children: [
      {
        id: "newOrders",
        text: "Baru",
        path: "/orders/new",
        type: "item",
        permission: ["Order@is_view"],
      },
      {
        id: "prosesOrders",
        text: "Proses",
        path: "/orders/process",
        type: "item",
        permission: ["Order@is_view"],
      },
      {
        id: "readyOrders",
        text: "Siap di pickup",
        path: "/orders/ready",
        type: "item",
        permission: ["Order@is_view"],
      },
      {
        id: "sendOrders",
        text: "Dikirim",
        path: "/orders/send",
        type: "item",
        permission: ["Order@is_view"],
      },
      {
        id: "completeOrders",
        text: "Selesai",
        path: "/orders/complete",
        type: "item",
        permission: ["Order@is_view"],
      },
      {
        id: "cancelOrders",
        text: "Batal",
        path: "/orders/cancel",
        type: "item",
        permission: ["Order@is_view"],
      },
      {
        id: "pendingOrders",
        text: "Pending",
        path: "/orders/pending",
        type: "item",
        permission: ["Order@is_view"],
      },
    ],
  },
  {
    id: "finances",
    text: "Keuangan",
    path: "#",
    type: "collapse",
    permission: ["Bank@is_view", "Withdraw@is_view"],
    icon: IcReport,
    children: [
      {
        id: "banks",
        text: "Bank",
        path: "/finance/bank",
        type: "item",
        permission: ["Bank@is_view"],
      },
      {
        id: "withdraw",
        text: "Withdraw",
        path: "/finance/withdraw",
        type: "item",
        permission: ["Withdraw@is_view"],
      },
    ],
  },
  {
    id: "reports",
    text: "Report",
    path: "#",
    type: "collapse",
    permission: ["Transaction@is_view", "Report Order@is_view"],
    icon: IcReport,
    children: [
      {
        id: "orderreport",
        text: "Order",
        path: "/report/order",
        type: "item",
        permission: ["Report Order@is_view"],
      },
      {
        id: "transactionreport",
        text: "Saldo",
        path: "/report/transaction",
        type: "item",
        permission: ["Transaction@is_view"],
      },
      {
        id: "closingkasirreport",
        text: "Closing Kasir",
        path: "/report/closing-kasir",
        type: "item",
        permission: ["Cashier Closing History@is_view"],
      },
    ],
  },
  {
    id: "users",
    text: "Pengguna",
    path: "#",
    type: "collapse",
    permission: ["User@is_view", "Role@is_view"],
    icon: IcUser,
    children: [
      {
        id: "userList",
        text: "Pengguna",
        path: "/users/list",
        type: "item",
        permission: ["User@is_view"],
      },
      {
        id: "role",
        text: "Role",
        path: "/users/roles",
        type: "item",
        permission: ["Role@is_view"],
      },
      {
        id: "storeaccesslist",
        text: "Akses Toko",
        path: "/users/store-access",
        type: "item",
        permission: ["Store Access@is_view"],
      },
    ],
  },
  {
    id: "setting",
    text: "Pengaturan",
    path: "#",
    type: "collapse",
    permission: [
      "Fee Setting@is_view",
      "App Setting@is_view",
      "Loyalty Setting@is_view",
      "Delivery Setting@is_view",
    ],
    icon: IcSetting,
    children: [
      // {
      //   id: "feesetting",
      //   text: "Pengaturan Biaya",
      //   path: "/setting/feesetting",
      //   type: "item",
      //   permission: ["Fee Setting@is_view"],
      // },
      {
        id: "appsetting",
        text: "Pengaturan Aplikasi",
        path: "/setting/appsetting",
        type: "item",
        permission: ["App Setting@is_view"],
      },
      {
        id: "loyaltysetting",
        text: "Pengaturan Loyalti",
        path: "/setting/loyaltysetting",
        type: "item",
        permission: ["Loyalty Setting@is_view"],
      },
      {
        id: "deliverysetting",
        text: "Pengaturan Pengiriman",
        path: "/setting/deliverysetting",
        type: "item",
        permission: ["Delivery Setting@is_view"],
      },
    ],
  },
];

export default features;
