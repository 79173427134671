import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  TransactionState,
  ActiveBalanceState,
  TransactionTotalState,
  TransactionAmountState,
  TransactionChartState,
  GetActiveBalanceProps,
} from './types';
import ApiService from 'src/services/ApiService';
import { getToken } from 'src/helper/generalFunction';

export interface TransactionListProps {
  offset?: number;
  limit?: number;
  store_id?: string | null;
  start_date?: string | null;
  end_date?: string;
  status?: 'IN' | 'OUT' | '';
}

export const getTransactions = createAsyncThunk(
  'transaction/get',
  async (props: TransactionListProps) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_URL}/merchant/finance/transaction?limit=${
          props.limit ?? 0
        }&offset=${props.offset ?? 0}${
          props.start_date ? `&start_date=${props.start_date}` : ''
        }${props.end_date ? `&end_date=${props.end_date}` : ''}${
          props.store_id ? `&store_id=${props.store_id}` : ''
        }${props.status ? `&status=${props.status}` : ''}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as TransactionState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as TransactionState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as TransactionState;
    }
  }
);

export const getActiveBalance = createAsyncThunk(
  'transaction/activebalance',
  async ({
    store_id,
    start_date,
    end_date,
    status,
    status_order,
  }: GetActiveBalanceProps) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.get(
        `${
          process.env.REACT_APP_API_URL
        }/merchant/finance/transaction/active-balance?${
          store_id ? 'store_id=' + store_id : ''
        }${start_date ? '&start_date=' + start_date : ''}${
          end_date ? '&end_date=' + end_date : ''
        }${status ? '&status=' + status : ''}${
          status_order ? '&status_order=' + status_order : ''
        }`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as ActiveBalanceState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as ActiveBalanceState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as ActiveBalanceState;
    }
  }
);

export const getTotalTransaction = createAsyncThunk(
  'transaction/dashboard/total',
  async ({
    start_date,
    end_date,
  }: {
    start_date: string;
    end_date: string;
  }) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_URL}/merchant/dashboard/total-transaction?start_date=${start_date}&end_date=${end_date}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as TransactionTotalState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as TransactionTotalState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as TransactionTotalState;
    }
  }
);

export const getAmountTransaction = createAsyncThunk(
  'transaction/dashboard/amount',
  async ({
    start_date,
    end_date,
  }: {
    start_date: string;
    end_date: string;
  }) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_URL}/merchant/dashboard/total-transaction-amount?start_date=${start_date}&end_date=${end_date}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as TransactionAmountState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as TransactionAmountState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as TransactionAmountState;
    }
  }
);

export const getChartTransaction = createAsyncThunk(
  'transaction/dashboard/chart',
  async () => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      let year = new Date().getFullYear();
      const response = await ApiService.get(
        `${
          process.env.REACT_APP_API_URL
        }/merchant/dashboard/chart/total-transaction-customer?start_date=${
          year + '-01'
        }&end_date=${year + '-12'}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as TransactionChartState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as TransactionChartState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as TransactionChartState;
    }
  }
);
