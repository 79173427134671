import React from "react";
import { Box, Grid } from "@mui/material";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import { titleCase } from "src/helper/generalFunction";

const styles = {
  container: {
    backgroundColor: "#ffff",
    display: "flex",
    padding: 5,
  },
  notes: {
    margin: 0,
    fontSize: '10px',
  },
  details: {
    marginLeft: "5px",
    marginBottom: "10px",
    flexDirection: "row",
  },
  movieTitle: {
    fontSize: "13px",
    color: "black",
    margin: "0 5px 0 0",
  },
  subtitle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 12,
  },
  vote_text: {
    fontSize: "13px",
    margin: 0
  },
};

interface PdfProps {
  data?: OrderProps;
}

interface OrderProps {
  invoice_number: string;
  order_number: string;
  merchant_name: string;
  customer_name: string;
  total_amount: string;
  order_type: string;
  status_order: string;
  order_queue: number;
  order_detail: orderDetailProps[];
  status: string;
  payment_type: string;
  payment_channel: string;
  total_amount_payment: string;
  payment_date: string;
}

interface orderDetailProps {
  product_id: number;
  product_name: string;
  product_price: string;
  product_qty: number;
  notes: string;
  productAddOn: productAddOnProps[];
}

interface productAddOnProps {
  product_id: number;
  product_name: string;
  product_price: string;
  product_qty: number;
  notes: string;
}

export function PdfDocument({ data }: PdfProps) {
  return (
    <div
      style={{
        backgroundColor: "#ffff",
        display: "flex",
        flexDirection: "column",
        padding: 25,
        width: "2400px",
      }}
    >
      <div
        style={{
          flexDirection: "row",
          marginBottom: "10px",
          display: "flex",
          marginLeft: "5px",
        }}
      >
        <p style={styles.movieTitle}>Menu :</p>
        <div
          style={{
            flexDirection: "column",
            display: "flex",
            width: "2000px",
          }}
        >
          {data && data.order_detail && data.order_detail.length !== 0
            ? data.order_detail.map((item, i) => {
              return (
                <div style={{ marginBottom: "2px" }} key={i}>
                  <p style={styles.vote_text}>
                    {item.product_name} ({item.product_qty}x)
                  </p>
                  {item.productAddOn && item.productAddOn.length !== 0 && (
                    <p style={styles.vote_text}>Additional:</p>
                  )}
                  {item.productAddOn && item.productAddOn.length !== 0
                    ? item.productAddOn.map((x, y) => {
                      return (
                        <p style={styles.vote_text} key={y}>
                          {x.product_name} ({x.product_qty}x)
                        </p>
                      );
                    })
                    : null}
                  <p style={styles.notes}>Notes: {item.notes}</p>
                </div>
              );
            })
            : null}
        </div>
      </div>
      <div
        style={{
          flexDirection: "row",
          marginBottom: "10px",
          display: "flex",
          marginLeft: "5px",
        }}
      >
        <p style={styles.movieTitle}>Nama Pelanggan : </p>
        <p style={{ ...styles.vote_text, wordSpacing: '2px' }}>{data && data.customer_name ? titleCase(data.customer_name) : ''}</p>
      </div>
      <div
        style={{
          flexDirection: "row",
          marginBottom: "10px",
          display: "flex",
          marginLeft: "5px",
        }}
      >
        <p style={styles.movieTitle}>Antrian : </p>
        <p style={styles.vote_text}>{data && data.order_queue}</p>
      </div>
      <div
        style={{
          flexDirection: "row",
          marginBottom: "10px",
          display: "flex",
          marginLeft: "5px",
        }}
      >
        <p style={styles.movieTitle}>Tipe :</p>
        <p style={styles.vote_text}>{data && data.order_type}</p>
      </div>
    </div>
  );
}
