import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  StoreListState,
  StoreGroupListState,
  CreateStoreType,
  StoreDetailState,
  NewStoreState,
  CreateGroupType,
  UpdateGroupType,
  NewGroupState,
  AddMemberGroupType,
} from "./types";
import { PaginationProps, PaginationGroupMemberProps } from "src/redux/types";
import ApiService from "src/services/ApiService";
import { getToken } from "src/helper/generalFunction";

export const getAllStore = createAsyncThunk(
  "stores/get",
  async (props: PaginationProps) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_URL}/merchant/store?limit=${
          props.limit ?? 10
        }&offset=${props.offset ?? 0}${
          props.filter ? "&filter=" + props.filter : ""
        }`,
        config
      );

      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as StoreListState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as StoreListState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as StoreListState;
    }
  }
);

export const getAllStoreMultiMerchant = createAsyncThunk(
  "stores-multi-merchant/get",
  async (props: PaginationProps) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_URL}/merchant/store/list?limit=${
          props.limit ?? 10
        }&offset=${props.offset ?? 0}${
          props.filter ? "&filter=" + props.filter : ""
        }`,
        config
      );

      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as StoreListState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as StoreListState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as StoreListState;
    }
  }
);
// export interface ShowRoleProps {
//     role_id: number;
//     access_token: string;
// }

export const showDetailStore = createAsyncThunk(
  "stores/show",
  async (store_id: number) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_URL}/merchant/store/${store_id}`,
        config
      );

      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as StoreDetailState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as StoreDetailState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as StoreDetailState;
    }
  }
);

export const createNewStore = createAsyncThunk(
  "stores/post",
  async (props: CreateStoreType) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.post(
        process.env.REACT_APP_API_URL + "/merchant/store",
        props,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as NewStoreState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as NewStoreState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as NewStoreState;
    }
  }
);

export const updateStore = createAsyncThunk(
  "stores/put",
  async (props: CreateStoreType) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const {
        store_name,
        store_phone,
        address,
        google_maps_url,
        province_id,
        area_id,
        city_id,
        suburb_id,
        store_logo,
        open_at,
        closed_at,
        status_open_store,
        store_banner,
      } = props;
      const response = await ApiService.put(
        `${process.env.REACT_APP_API_URL}/merchant/store/${props.id}`,
        {
          store_name,
          store_phone,
          address,
          google_maps_url,
          province_id,
          area_id,
          city_id,
          suburb_id,
          store_logo,
          store_banner,
          open_at,
          closed_at,
          status_open_store,
        },
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as StoreListState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as StoreListState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as StoreListState;
    }
  }
);

export const removeStore = createAsyncThunk(
  "stores/delete",
  async (store_id: number) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.delete(
        `${process.env.REACT_APP_API_URL}/merchant/store/${store_id}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as StoreListState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as StoreListState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as StoreListState;
    }
  }
);

export const getAllGroupStore = createAsyncThunk(
  "qr-groups/get",
  async (props: PaginationProps) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_URL}/merchant/qr-group?limit=${
          props.limit ?? 10
        }&offset=${props.offset ?? 0}${
          props.filter ? "&filter=" + props.filter : ""
        }`,
        config
      );

      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as StoreGroupListState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as StoreGroupListState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as StoreGroupListState;
    }
  }
);

export const showDetailGroupStore = createAsyncThunk(
  "qr-group/show",
  async (group_id: any) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_URL}/merchant/qr-group/${group_id}/detail`,
        config
      );

      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as StoreDetailState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as StoreDetailState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as StoreDetailState;
    }
  }
);

// export const showDetailGroupStore = createAsyncThunk(
//   "qr-group/show",
//   async (props: PaginationGroupMemberProps) => {
//     try {
//       const config = {
//         headers: {
//           token: getToken(),
//         },
//       };
//       const response = await ApiService.get(
//         `${process.env.REACT_APP_API_URL}/merchant/qr-group/${props.group_id}/detail?limit=${
//           props.limit ?? 10
//         }&offset=${props.offset ?? 0}${
//           props.filter ? "&filter=" + props.filter : ""
//         }`,
//         config
//       );

//       if (response && response.data) {
//         return {
//           data: response.data,
//           status: "success",
//           error: null,
//         } as unknown as StoreDetailState;
//       } else {
//         return {
//           data: response.data?.message,
//           status: "empty",
//           error: null,
//         } as unknown as StoreDetailState;
//       }
//     } catch (err) {
//       if (err)
//         return {
//           data: "Something went wrong!",
//           status: "error",
//           error: err,
//         } as unknown as StoreDetailState;
//     }
//   }
// );

export const createNewGroupStore = createAsyncThunk(
  "qrgroup/post",
  async (props: CreateGroupType) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.post(
        process.env.REACT_APP_API_URL + "/merchant/qr-group",
        props,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as NewGroupState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as NewGroupState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as NewGroupState;
    }
  }
);

export const updateGroupStore = createAsyncThunk(
  "qrgroup/put",
  async (props: UpdateGroupType) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const {
        id,
        group_name,
        phone,
        address,
        address_type,
        url_gmap,
        province_id,
        area_id,
        city_id,
        suburb_id,
        group_logo,
      } = props;
      const response = await ApiService.put(
        `${process.env.REACT_APP_API_URL}/merchant/qr-group/${id}`,
        {
          group_name,
          phone,
          address,
          address_type,
          url_gmap,
          province_id,
          area_id,
          city_id,
          suburb_id,
          group_logo,
        },
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as StoreListState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as StoreListState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as StoreListState;
    }
  }
);

export const removeGroupStore = createAsyncThunk(
  "qr-group/delete",
  async (group_id: number) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.delete(
        `${process.env.REACT_APP_API_URL}/merchant/qr-group/${group_id}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as StoreGroupListState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as StoreGroupListState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as StoreGroupListState;
    }
  }
);

export const addGroupStoreMember = createAsyncThunk(
  "qr-group-member/post",
  async (props: AddMemberGroupType) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.post(
        process.env.REACT_APP_API_URL + "/merchant/qr-group-store",
        props,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as NewGroupState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as NewGroupState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as NewGroupState;
    }
  }
);

export const removeGroupStoreMember = createAsyncThunk(
  "qr-group-member/delete",
  async (store_id: number) => {
    try {
      const config = {
        headers: {
          token: getToken(),
        },
      };
      const response = await ApiService.delete(
        `${process.env.REACT_APP_API_URL}/merchant/qr-group-store/${store_id}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as StoreGroupListState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as StoreGroupListState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as StoreGroupListState;
    }
  }
);
