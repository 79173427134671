import React, { useState } from "react";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import { useNavigate, useParams } from "react-router-dom";
import useRedux from "src/redux/useRedux";
import Card from "src/components/Cards/card";
import { NormalText } from "src/components/styled/text.styled";
import { Button } from "src/components/styled/button.styled";
import TextField from "src/components/Inputs/TextField";
import Dropdown, { OptionProps } from "src/components/Inputs/Dropdown";
import {
  Stack,
  Box,
  Grid,
  FormHelperText,
  CircularProgress,
  Typography,
  Switch,
} from "@mui/material";
import { ProvincesData, getAllProvinces } from "src/redux/regions/province";
import { CityData, getAllCity } from "src/redux/regions/city";
import { getAllSubUrban, SubUrbanData } from "src/redux/regions/suburban";
import { getAllArea, AreaData } from "src/redux/regions/area";
import { updateStore, showDetailStore } from "src/redux/storelist";
import { uploadImage } from "src/helper/uploadFile";
import { ToastContainer, toast } from "react-toastify";
import styled from "styled-components";
// import TimePicker from "rc-time-picker";

const UploadLabel = styled.label`
  width: 9%;
  height: 40px;
  background-color: white;
  color: black;
  padding: 10px;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 2px;
  border: 1px solid #1985d2;
  text-align: center;
  &:hover {
    background-color: #1985d2;
    color: #fff;
  }
`;

const UpdateStoreForm = () => {
  const navigate = useNavigate();
  const { thunkDispatch } = useRedux();
  const { id } = useParams();
  const [provinces, setProvinces] = React.useState<ProvincesData[]>();
  const [cities, setCities] = React.useState<CityData[]>();
  const [listSubUrban, setListSubUrban] = React.useState<SubUrbanData[]>();
  const [areas, setAreas] = React.useState<AreaData[]>();
  const [invalidUrl, setInvalidUrl] = React.useState(false);
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [geolocation, setGeolocation] = React.useState({
    lat: "",
    long: "",
  });
  const [payload, setPayload] = React.useState({
    store_name: "",
    store_phone: "",
    address: "",
    province_id: 0,
    city_id: 0,
    suburb_id: 0,
    area_id: 0,
    google_maps_url: "",
    store_logo: "",
    store_banner: "",
    open_at: "",
    closed_at: "",
    status_open_store: false,
  });

  const [errorValidasi, setErrorValidasi] = React.useState({
    logoToko: "",
  });
  const [errorBanner, setErrorBanner] = React.useState({
    bannerToko: "",
  });
  const [loadingImg, setLoadingImg] = React.useState(false);
  const [loadingBanner, setLoadingBanner] = React.useState(false);
  const [storeStatus, setStore] = React.useState(false);

  const fetchCities = (prov_id: number) => {
    thunkDispatch(getAllCity(prov_id))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          console.log("C", res.data.data);
          setCities(res.data.data.cities);
        }
      });
  };

  const fetchSuburban = (city_id: number) => {
    thunkDispatch(getAllSubUrban(city_id))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setListSubUrban(res.data.data.suburbs);
        }
      });
  };

  const fetchArea = (suburb_id: number) => {
    thunkDispatch(getAllArea(suburb_id))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setAreas(res.data.data.areas);
        }
      });
  };

  const validMapsUrl = (url: string) => {
    var regex = new RegExp("@(.*),(.*),");
    return url.match(regex);
  };

  const validPhone = () => {
    if (
      payload.store_phone &&
      String(payload.store_phone)[0] === "0" &&
      payload.store_phone.length >= 11
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getGolocation = (url: string) => {
    var lat_long_match = validMapsUrl(url);
    if (lat_long_match) {
      var lat = lat_long_match[1];
      var long = lat_long_match[2];
      setGeolocation({
        lat: lat,
        long: long,
      });
      setInvalidUrl(false);
    } else {
      setInvalidUrl(true);
    }
  };

  React.useEffect(() => {
    if (id) {
      thunkDispatch(showDetailStore(Number(id)))
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            var response = res.data.data;
            setPayload({
              store_name: response.store_name,
              store_phone: response.store_phone,
              address: response.address,
              province_id: response.province.id,
              city_id: response.city.id,
              suburb_id: response.suburb.id,
              area_id: response.area.id,
              google_maps_url: response.google_maps_url,
              store_logo: response.store_logo,
              store_banner: response.store_banner,
              open_at: response.open_at,
              closed_at: response.closed_at,
              status_open_store: response.status_open_store,
            });
            setGeolocation({
              lat: String(response.latitude),
              long: String(response.longitude),
            });
            fetchCities(response.province.id);
            fetchSuburban(response.city.id);
            fetchArea(response.suburb.id);
          }
        });
    }
    thunkDispatch(getAllProvinces())
      .unwrap()
      .then((res: any) => {
        if (res && res.status === "success") {
          setProvinces(res.data.data);
        }
      });
  }, []);

  const changeStatus = (e: any) => {
    // console.log('status', e.target.checked);
    
    setPayload({ ...payload, status_open_store: e.target.checked });
  }

  const handleImage = (e: any, data: string) => {
    if (e.target.files && e.target.files[0]) {
      let imageName = e.target.files[0];
      let fileSize = imageName.size / (1024 * 1024);
      var img = new Image();
      img.src = window.URL.createObjectURL(imageName);
      img.onload = function () {
        let width = img.naturalWidth;
        let height = img.naturalHeight;

        let ValidHeight = 300;
        let ValidWidth = 300;

        if (width > ValidWidth || height > ValidHeight || fileSize > 2) {
          setErrorValidasi({
            ...errorValidasi,
            [data]: `Resolusi gambar maks. ${ValidWidth}x${ValidHeight} & ukuran maks. 2MB`,
          });
        } else {
          handleUploadFile(imageName, data).then((res: any) => {
            console.log("OK");
          });
          setErrorValidasi({
            ...errorValidasi,
            [data]: "",
          });
        }
      };
    }
  };

  const handleBanner = (e: any, data: string) => {
    if (e.target.files && e.target.files[0]) {
      let imageName = e.target.files[0];
      let fileSize = imageName.size / (1024 * 1024);
      var img = new Image();
      img.src = window.URL.createObjectURL(imageName);
      img.onload = function () {
        let width = img.naturalWidth;
        let height = img.naturalHeight;

        let ValidHeight = 1920;
        let ValidWidth = 1080;

        if (width > ValidWidth || height > ValidHeight || fileSize > 2) {
          setErrorBanner({
            ...errorBanner,
            [data]: `Resolusi gambar maks. ${ValidWidth}x${ValidHeight} & ukuran maks. 2MB`,
          });
        } else {
          handleUploadBanner(imageName, data).then((res: any) => {
            console.log("OK");
          });
          setErrorBanner({
            ...errorBanner,
            [data]: "",
          });
        }
      };
    }
  };


  const handleUploadFile = async (file: any, typeUpload: string) => {
    let nameUpload = typeUpload;
    setLoadingImg(true);
    let formdata = new FormData();
    formdata.append("image_count", "1");
    formdata.append("image-1", file);
    await uploadImage(formdata, "setting")
      .then((res) => {
        if (res.status === 200) {
          setLoadingImg(false);
          setPayload({
            ...payload,
            store_logo: res.data.data["image-1"],
          });
          //console.log(payload);
        }
        setLoadingImg(false);
      })
      .catch(() => {
        setLoadingImg(false);
        setErrorValidasi({
          ...errorValidasi,
          [nameUpload]: "Gagal mengupload image",
        });
      });
  };

  const handleUploadBanner = async (file: any, typeUpload: string) => {
    let nameUpload = typeUpload;
    setLoadingBanner(true);
    let formdata = new FormData();
    formdata.append("image_count", "1");
    formdata.append("image-1", file);
    await uploadImage(formdata, "setting")
      .then((res) => {
        if (res.status === 200) {
          setLoadingBanner(false);
          setPayload({
            ...payload,
            store_banner: res.data.data["image-1"],
          });
          //console.log(payload);
        }
        setLoadingBanner(false);
      })
      .catch(() => {
        setLoadingBanner(false);
        setErrorBanner({
          ...errorBanner,
          [nameUpload]: "Gagal mengupload image",
        });
      });
  };

  const handleSubmit = () => {
    setIsSubmit(true);
    console.log(payload);
    if (
      payload.store_name &&
      payload.address &&
      payload.province_id != 0 &&
      payload.city_id != 0 &&
      payload.suburb_id != 0 &&
      payload.area_id != 0 &&
      payload.store_name.length <= 32 &&
      validPhone() &&
      validMapsUrl(payload.google_maps_url)
    ) {
      thunkDispatch(updateStore({ ...payload, id: Number(id) }))
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            toast.success("Berhasil mengubah informasi toko");
            setTimeout(() => {
              navigate("/stores/store/list");
            }, 1500);
          } else {
            toast.error("Terjadi kesalahan, silahkan coba lagi");
          }
        })
        .catch((err) => {
          toast.error("Terjadi kesalahan, silahkan coba lagi");
          if (process.env.NODE_ENV !== "production") {
            console.log("Got Error", err);
          }
        });
    } else {
      toast.warning("Silahkan cek kembali inputan anda!");
    }
  };

  const handleDeleteLogo = () => {
    setLoadingImg(true);
    setPayload({ ...payload, store_logo: "" });
    setTimeout(() => {
      setLoadingImg(false);
    }, 500);
  };

  const handleDeleteBanner = () => {
    setLoadingBanner(true);
    setPayload({ ...payload, store_banner: "" });
    setTimeout(() => {
      setLoadingBanner(false);
    }, 500);
  };

  return (
    <MerchantLayout>
      <ToastContainer />
      <Card>
        <NormalText fontWeight="bold" fontSize="20px" margin="0 0 20px 0">
          Ubah Toko
        </NormalText>
        <TextField
          label={"Nama Toko"}
          isRequired
          validationText={
            isSubmit && !payload.store_name
              ? "Nama toko wajib diisi"
              : isSubmit && payload.store_name.length > 32
              ? "Maksimal karakter untuk nama toko 32 karakter"
              : ""
          }
          validationType={
            (isSubmit && !payload.store_name) ||
            (isSubmit && payload.store_name.length > 32)
              ? "ERROR"
              : ""
          }
          value={payload.store_name}
          onChange={(e: any) => {
            setPayload({ ...payload, store_name: e.target.value });
          }}
        ></TextField>
        <TextField
          label={"Alamat"}
          isRequired
          validationText="Alamat wajib diisi"
          validationType={isSubmit && !payload.address ? "ERROR" : ""}
          value={payload.address}
          onChange={(e: any) => {
            setPayload({ ...payload, address: e.target.value });
          }}
        ></TextField>
        <Box width={"300px"}>
          <Dropdown
            label={"Provinsi"}
            validationType={
              isSubmit && payload.province_id === 0 ? "ERROR" : ""
            }
            validationText={"Provinsi wajib diisi"}
            isRequired
            options={
              provinces
                ? provinces.map((item) => {
                    return {
                      label: item.province_name,
                      value: item.id,
                    } as OptionProps;
                  })
                : []
            }
            value={payload.province_id}
            onChange={(value: number) => {
              fetchCities(value);
              setPayload({
                ...payload,
                province_id: value,
                city_id: 0,
                suburb_id: 0,
                area_id: 0,
              });
            }}
          />
          <Dropdown
            label={"Kota"}
            isRequired
            validationType={isSubmit && payload.city_id === 0 ? "ERROR" : ""}
            validationText={"Kota wajib diisi"}
            options={
              cities
                ? cities.map((item) => {
                    return {
                      label: item.city_name,
                      value: item.id,
                    } as OptionProps;
                  })
                : []
            }
            value={payload.city_id}
            onChange={(value: number) => {
              fetchSuburban(value);
              setPayload({
                ...payload,
                city_id: value,
                suburb_id: 0,
                area_id: 0,
              });
            }}
          />
          <Dropdown
            label={"Kecamatan"}
            isRequired
            validationType={isSubmit && payload.suburb_id === 0 ? "ERROR" : ""}
            validationText={"Kecamatan wajib diisi"}
            options={
              listSubUrban
                ? listSubUrban.map((item) => {
                    return {
                      label: item.suburbName,
                      value: item.id,
                    } as OptionProps;
                  })
                : []
            }
            value={payload.suburb_id}
            onChange={(value: any) => {
              setPayload({ ...payload, suburb_id: value });
              fetchArea(value);
            }}
          />

          <Dropdown
            label={"Kelurahan"}
            isRequired
            validationType={isSubmit && payload.area_id === 0 ? "ERROR" : ""}
            validationText={"Kelurahan wajib diisi"}
            options={
              areas
                ? areas.map((item) => {
                    return {
                      label: item.area_name,
                      value: item.id,
                    } as OptionProps;
                  })
                : []
            }
            value={payload.area_id}
            onChange={(value: number) => {
              setPayload({ ...payload, area_id: value });
            }}
          />
          <TextField
            label={"Nomor Telepon"}
            isRequired
            type={"tel"}
            validationText={
              isSubmit && !payload.store_phone
                ? "Nomor Telepon wajib diisi"
                : (isSubmit &&
                    payload.store_phone &&
                    payload.store_phone.length < 11) ||
                  (isSubmit &&
                    payload.store_phone &&
                    String(payload.store_phone)[0] !== "0")
                ? "Panjang nomor telepon min. 11 digit dan diawali dengan nol (0)"
                : ""
            }
            validationType={
              (isSubmit && !payload.store_phone) ||
              (isSubmit && payload.store_phone.length < 11) ||
              (isSubmit && String(payload.store_phone)[0] !== "0")
                ? "ERROR"
                : ""
            }
            value={payload.store_phone}
            onChange={(e: any) => {
              setPayload({ ...payload, store_phone: e.target.value });
            }}
          ></TextField>
        </Box>
        <TextField
          label={"URL Google Maps"}
          isRequired
          validationText={
            invalidUrl
              ? "Url yang and masukan tidak valid"
              : isSubmit && !payload.google_maps_url
              ? "URL Google Maps wajib diisi"
              : ""
          }
          validationType={
            invalidUrl || (isSubmit && !payload.google_maps_url) ? "ERROR" : ""
          }
          value={payload.google_maps_url}
          onChange={(e: any) => {
            setPayload({ ...payload, google_maps_url: e.target.value });
            getGolocation(e.target.value);
          }}
        ></TextField>
        <TextField
          label={"Latitude"}
          isRequired
          readonly
          value={geolocation.lat}
        ></TextField>
        <TextField
          label={"Longitude"}
          isRequired
          readonly
          value={geolocation.long}
        ></TextField>
        <Box>
          <NormalText textAlign="left" margin={"7px 0 5px 0"} fontSize={"12px"}>
            Logo Toko
          </NormalText>
          <Grid container spacing={2} alignItems={"center"}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <UploadLabel
                htmlFor="logoToko"
                style={{
                  width: "100%",
                  backgroundColor: "#ccc",
                  borderColor: "#ccc",
                  cursor: "pointer",
                }}
              >
                {loadingImg ? <CircularProgress size={15} /> : null}{" "}
                {loadingImg ? "" : "Pilih Gambar"}
              </UploadLabel>
              {payload && payload.store_logo ? (
                <UploadLabel
                  style={{
                    width: "100%",
                    backgroundColor: "#E56C75",
                    borderColor: "#E56C75",
                    color: "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={handleDeleteLogo}
                >
                  {loadingImg ? <CircularProgress size={15} /> : null}{" "}
                  {loadingImg ? "" : "Hapus Gambar"}
                </UploadLabel>
              ) : null}

              <input
                accept="image/*"
                id="logoToko"
                multiple
                type="file"
                onChange={(e: any) => handleImage(e, "logoToko")}
                style={{ display: "none" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              {payload.store_logo && (
                <img
                  src={payload.store_logo}
                  style={{ height: 150, width: 150, marginLeft: "30px" }}
                  className="brand-image"
                  alt="image brand"
                  loading="lazy"
                />
              )}
            </Grid>
          </Grid>
          <FormHelperText style={{ color: "#1565c0" }}>
            Resolusi gambar maks. 300x300 Pixels {"&"} ukuran maks. 2MB
          </FormHelperText>
          <FormHelperText style={{ color: "#f12147" }}>
            {errorValidasi.logoToko}
          </FormHelperText>


          <NormalText textAlign="left" margin={"7px 0 5px 0"} fontSize={"12px"}>
            Banner Toko
          </NormalText>
          <Grid container spacing={2} alignItems={"center"}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <UploadLabel
                htmlFor="bannerToko"
                style={{
                  width: "100%",
                  backgroundColor: "#ccc",
                  borderColor: "#ccc",
                  cursor: "pointer",
                }}
              >
                {loadingBanner ? <CircularProgress size={15} /> : null}{" "}
                {loadingBanner ? "" : "Pilih Gambar"}
              </UploadLabel>
              {payload && payload.store_banner ? (
                <UploadLabel
                  style={{
                    width: "100%",
                    backgroundColor: "#E56C75",
                    borderColor: "#E56C75",
                    color: "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={handleDeleteBanner}
                >
                  {loadingBanner ? <CircularProgress size={15} /> : null}{" "}
                  {loadingBanner ? "" : "Hapus Gambar"}
                </UploadLabel>
              ) : null}

              <input
                accept="image/*"
                id="bannerToko"
                multiple
                type="file"
                onChange={(e: any) => handleBanner(e, "bannerToko")}
                style={{ display: "none" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              {payload.store_banner && (
                <img
                  src={payload.store_banner}
                  style={{ height: 150, width: 150, marginLeft: "30px" }}
                  className="brand-image"
                  alt="image brand"
                  loading="lazy"
                />
              )}
            </Grid>
          </Grid>
          <FormHelperText style={{ color: "#1565c0" }}>
            Resolusi gambar maks. 1080x1920 Pixels {"&"} ukuran maks. 2MB
          </FormHelperText>
          <FormHelperText style={{ color: "#f12147" }}>
            {errorBanner.bannerToko}
          </FormHelperText>
          
          <NormalText>
              Jam Buka Toko
          </NormalText>
          <input
            type="time"
            value={payload.open_at}
            onChange={(e: any) => {
              setPayload({ ...payload, open_at: e.target.value });
            }}
          />

          <NormalText>
              Jam Tutup Toko
          </NormalText>
          <input
            type="time"
            value={payload.closed_at}
            onChange={(e: any) => {
              setPayload({ ...payload, closed_at: e.target.value });
            }}
          />
        
          <NormalText>Status Toko
            <Stack direction="row" spacing={1} alignItems="center">
              <Switch 
                // onChange={(e: boolean) => {
                //   setPayload({ ...payload, status_open_store: e.target.value });
                // }}
                checked={payload.status_open_store}
                value={payload.status_open_store}   
                onChange={changeStatus}
              />
              <Typography>{payload.status_open_store ? 'Buka' : 'Tutup'}</Typography>
            </Stack>
          </NormalText>
        </Box>

        <Stack direction={"row"} spacing={2} margin={"20px 0 20px 0"}>
          <Button
            variant="danger"
            onClick={() => navigate("/stores/store/list")}
          >
            Batal
          </Button>
          <Button variant="primary" onClick={() => handleSubmit()}>
            Simpan
          </Button>
        </Stack>
      </Card>
    </MerchantLayout>
  );
};

export default UpdateStoreForm;
