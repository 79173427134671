import React, { useState } from "react";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import UserGroups from "../layout";
import useRedux from "src/redux/useRedux";
import { getList, OrderProps, updateStatus } from "src/redux/order";
// import PopUp from "src/components/Modal/modal";
import { NormalText } from "src/components/styled/text.styled";
import Confirmation from "src/components/Modal/confirmation";
import Tables from "src/components/Table/tableData";
import {
  Button as ButtonMUI,
  Stack,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { PdfDocument } from "../generatePDf";
import jsPDF from "jspdf";
import { renderToString } from "react-dom/server";
import FilterStore from "../storeFilter";
import CheckPermission from "src/helper/checkPermission";
import { useNavigate } from "react-router-dom";

const Page = () => {
  const navigate = useNavigate();
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState("");
  const [filterStore, setFilterStore] = useState(0);
  const [filterProductType, setFilterProductType] = useState("all");
  const [loading, setLoading] = useState(true);
  const [totalData, setTotalData] = useState(0);
  const [data, setData] = useState<OrderProps[]>();
  const [changeStatus, setChangeStatus] = useState(false);
  const [orderNo, setOrderNo] = useState("");
  const product_type = [
    { value: "all", label: "Semua" },
    { value: "global", label: "Produk Global" },
    { value: "store", label: "Produk Toko" },
  ];

  const print = (data: any) => {
    const string = renderToString(<PdfDocument data={data} />);
    const pdf = new jsPDF({
      orientation: "p",
      unit: "px",
      format: "a4",
    });
    pdf.setFont("arial", "bold");
    pdf.setFontSize(14);
    pdf.html(string, {
      callback: function (pdf) {
        // pdf.save(`newOrder-${data.order_number}`);
        pdf.autoPrint();
        pdf.output("dataurlnewwindow");
      },
    });
  };

  const columns = [
    {
      name: "Ringkasan Pesanan",
      width: "23%", // added line here
      style: { textAlign: "left", paddingLeft: "5px", paddingRight: "5px" },
      selector: (row: any) => {
        return (
          <div>
            <NormalText fontSize={"12px"} margin={"0px 0px 0px 0px"}>
              Order No. {row.order_number}
            </NormalText>
            <NormalText fontSize={"12px"} margin={"0px 0px 0px 0px"}>
              Payment Date {row.payment_date}
            </NormalText>
          </div>
        );
      },
      sortable: true,
      center: true,
    },
    {
      name: "Status Pesanan",
      width: "15%", // added line here
      style: { textAlign: "left", paddingLeft: "5px", paddingRight: "5px" },
      selector: (row: any) => {
        return (
          <div>
            <NormalText margin={"0px 0px 15px 0px"} fontSize={"12px"}>
              {row.status_order === "DELIVERY" ? "Dikirim" : row.status_order}
            </NormalText>
          </div>
        );
      },
      sortable: true,
      center: true,
    },
    {
      name: "Produk & Qty",
      width: "15%", // added line here
      style: { textAlign: "left", paddingLeft: "5px", paddingRight: "5px" },
      selector: (row: any) => {
        return (
          <div style={{ paddingTop: 10, paddingBottom: 10, height: "100%" }}>
            {row.order_detail && row.order_detail.length !== 0
              ? row.order_detail.map((item: any, i: number) => {
                  return (
                    <div key={i}>
                      <NormalText fontSize={"12px"} margin={"0px 0px 0px 0px"}>
                        {item.product_name} ({item.product_qty}x)
                      </NormalText>
                      <NormalText fontSize={"12px"} margin={"5px 0px 0px 0px"}>
                        {item.productAddOn && item.productAddOn.length !== 0
                          ? "Additional:"
                          : ""}
                      </NormalText>
                      {item.productAddOn && item.productAddOn.length !== 0
                        ? item.productAddOn.map((x: any, y: number) => {
                            return (
                              <NormalText
                                fontSize={"12px"}
                                margin={"5px 0px 5px 15px"}
                              >
                                - {x.product_name} ({x.product_qty}x)
                              </NormalText>
                            );
                          })
                        : null}
                    </div>
                  );
                })
              : null}
          </div>
        );
      },
      sortable: true,
      center: true,
    },
    {
      name: "Antrian",
      width: "10%", // added line here
      style: { textAlign: "left", paddingLeft: "5px", paddingRight: "5px" },
      selector: (row: any) => {
        return (
          <NormalText margin={"0px 0px 0px 0px"} fontSize={"12px"}>
            {row.order_queue}
          </NormalText>
        );
      },
      sortable: true,
      center: true,
    },

    {
      name: "Customer",
      width: "18%", // added line here
      style: {
        textAlign: "left",
        paddingLeft: "5px",
        paddingRight: "5px",
        flexWrap: "wrap",
      },
      selector: (row: any) => {
        return (
          <NormalText margin={"0px 0px 0px 0px"} fontSize={"12px"}>
            {row.customer_name}
          </NormalText>
        );
      },
      sortable: true,
      center: true,
    },
    {
      name: "Tipe",
      width: "9%", // added line here
      style: { textAlign: "center", paddingLeft: "5px", paddingRight: "5px" },
      selector: (row: any) => {
        return (
          <NormalText margin={"0px 0px 0px 0px"} fontSize={"12px"}>
            {row.order_type}
          </NormalText>
        );
      },
      sortable: true,
      center: true,
    },
    {
      name: "Aksi",
      center: true,
      width: "10%", // added line here
      style: { textAlign: "left", paddingLeft: "5px", paddingRight: "5px" },
      cell: (row: any) => {
        return (
          <Stack
            sx={{ padding: "5px 0" }}
            direction="column"
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            {CheckPermission("Order", "is_edit") && (
              <ButtonMUI
                size="small"
                sx={{ height: 20, width: "100%" }}
                variant="contained"
                onClick={() => {
                  setOrderNo(row.order_number);
                  setChangeStatus(true);
                }}
                color="success"
              >
                Selesai
              </ButtonMUI>
            )}
            {CheckPermission("Order", "is_edit") && (
              <ButtonMUI
                size="small"
                sx={{ height: 20, width: "100%" }}
                variant="contained"
                color="warning"
                onClick={() => print(row)}
              >
                Cetak
              </ButtonMUI>
            )}
            <ButtonMUI
              size="small"
              sx={{ height: 20, width: "100%" }}
              variant="contained"
              color="primary"
              onClick={() => navigate(`/orders/${row.order_number}/detail`)}
            >
              Detail
            </ButtonMUI>
          </Stack>
        );
      },
    },
  ];

  const [openModal, setOpenModal] = useState(false);
  const [statusModal, setStatusModal] = useState("");

  const {
    thunkDispatch,
    storeState: {
      Auth: {
        status,
        data: { user_type },
      },
      AppOption: { selected_store },
      AppSetting,
    },
  } = useRedux();

  const handleStatus = () => {
    let dataObject = {
      data: { order_number: orderNo, status_order: "CLOSED" },
    };
    thunkDispatch(updateStatus(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          setLoading(false);
          setOpenModal(true);
          setStatusModal("success");
          getAll();
        } else {
          setLoading(false);
          setOpenModal(true);
          setStatusModal("failed");
        }
      })
      .catch((err) => {
        setLoading(false);
        setStatusModal("failed");
        setOpenModal(true);
        console.error(err);
      });
    setLoading(false);
  };

  const getAll = () => {
    setLoading(true);
    let storeId = String(filterStore ?? "");
    if (
      status &&
      status === "success" &&
      user_type &&
      user_type !== "merchant"
    ) {
      storeId = String(
        selected_store && selected_store.id ? selected_store.id : ""
      );
    }
    let dataObject = {
      offset: offset - 1,
      limit: limit,
      filter: filter,
      store_id: storeId,
      status: "DELIVERY",
      product_type: filterProductType,
    };
    thunkDispatch(getList(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status == "success") {
          console.log(res.data.data);
          if (res.data.data) {
            setData(res.data.data);
          }
          setTotalData(
            res.data && res.data.meta && res.data.meta.total
              ? res.data.meta.total
              : 0
          );
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  React.useEffect(() => {
    getAll();
  }, [offset, filter, limit, selected_store, filterStore, filterProductType]);

  return (
    <MerchantLayout>
      <Confirmation
        handleConfirm={() => {
          if (changeStatus) {
            handleStatus();
          } else if (statusModal === "success") {
            getAll();
          }
          setOpenModal(false);
          setChangeStatus(false);
        }}
        open={changeStatus}
        handleClose={() => {
          setOpenModal(false);
          setChangeStatus(false);
        }}
        hasCancel={true}
        title={
          changeStatus
            ? "Apa anda yakin?"
            : statusModal === "success"
            ? "Sukses"
            : "Gagal"
        }
        description={
          changeStatus
            ? "Apakah kamu ingin ubah status jadi selesai?"
            : statusModal === "success"
            ? "Berhasil mengubah status"
            : AppSetting?.error?.response?.data?.message
            ? AppSetting.error.response.data.message
            : "Gagal mengubah status"
        }
      ></Confirmation>
      <UserGroups tabActive={3}>
        <Tables
          records={data}
          columns={columns}
          title=""
          isSearch={true}
          addButton={false}
          titleAdd=""
          onChangeSearch={(e) => setFilter(e.target.value)}
          valueSearch={filter}
          handleAddButton={() => console.log("tambah")}
          progressPending={loading}
          paginationPerPage={limit}
          totalRow={totalData}
          filter={
            user_type && user_type === "merchant" ? (
              <FilterStore
                value={filterStore}
                onChange={(value: number) => setFilterStore(value)}
              />
            ) : null
          }
          filterByProduct={
            <Stack direction={"column"} alignItems={"start"} spacing={1}>
              <NormalText textAlign="start" fontSize={"14px"}>
                Filter Berdasarkan Tipe Produk
              </NormalText>
              <FormControl sx={{ minWidth: 250 }}>
                <Select
                  value={filterProductType}
                  onChange={(e) => setFilterProductType(e.target.value)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{ height: 40 }}
                >
                  {product_type.map((item: any, index: any) => {
                    return (
                      <MenuItem key={"option" + index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Stack>
          }
          onChangePage={(page) => setOffset(page)}
          onChangeRowsPerPage={(currentRowsPerPage) =>
            setLimit(currentRowsPerPage)
          }
        />
      </UserGroups>
    </MerchantLayout>
  );
};

export default Page;
