import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from 'firebase/app';

var firebaseConfig = {
  apiKey: "AIzaSyDm0brjRi-8V6fa98XFdNfCGBLXVeBmF_k",
  authDomain: "first-cloud-messaging-d0d2b.firebaseapp.com",
  projectId: "first-cloud-messaging-d0d2b",
  storageBucket: "first-cloud-messaging-d0d2b.appspot.com",
  messagingSenderId: "558789874172",
  appId: "1:558789874172:web:f1f1e7f0324be813ece279",
  measurementId: "G-W201GNG96F"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);



export const fetchToken = (topic, condition) => {
  return getToken(messaging, {vapidKey: 'BOE7Ja6_LVICtLRcUs14e4wWA4lHUT0knp-jcFmM7wSlscDEVNw1bxeDrz2_etgkI-biQVNPe3GRZ3uK4acPfFQ'})
  .then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      // Track the token -> client mapping, by sending to backend server
      
      // set topic(subscribe) or unset topic(unsubscribe)
      if(condition === "SUBSCRIBE"){
        subscribeTokenToTopic(currentToken, "store"+topic)
      }
      if(condition === "UNSUBSCRIBE"){
        unsubscribeTokenFromTopic(currentToken, "store"+topic)
      }

    } else {
      console.log('No registration token available. Request permission to generate one.');
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});

export const subscribeTokenToTopic = (token, topic) => {
  fetch('https://iid.googleapis.com/iid/v1/'+token+'/rel/topics/'+topic, {
    method: 'POST',
    headers: new Headers({
      'Authorization': 'key='+`AAAAghp40fw:APA91bGE-fuXPVinn0QdKLNfawr2CVu542bwV8Z_0VBZuKAF5bFfcOhnK-ccbjoHuog2sIpLfck83LyqW_7MvHarqLex6gJ5SteZk-QrLloOA_DyD8y3g3S6oBKYiCfe1hyQZzzEMDta	
      `
    })
  }).then(response => {
    if (response.status < 200 || response.status >= 400) {
      throw 'Error subscribing to topic: '+response.status + ' - ' + response.text();
    }
    console.log('Subscribed to "'+topic+'"');
  }).catch(error => {
    console.error(error);
  })
}

export const unsubscribeTokenFromTopic = (token, topic) => {
  fetch('https://iid.googleapis.com/iid/v1/'+token+'/rel/topics/'+topic, {
    method: 'DELETE',
    headers: new Headers({
      'Authorization': 'key='+`AAAAghp40fw:APA91bGE-fuXPVinn0QdKLNfawr2CVu542bwV8Z_0VBZuKAF5bFfcOhnK-ccbjoHuog2sIpLfck83LyqW_7MvHarqLex6gJ5SteZk-QrLloOA_DyD8y3g3S6oBKYiCfe1hyQZzzEMDta	
      `
    })
  }).then(response => {
    if (response.status < 200 || response.status >= 400) {
      throw 'Error unsubscribing to topic: '+response.status + ' - ' + response.text();
    }
    console.log('Unsubscribed from "'+topic+'"');
  }).catch(error => {
    console.error(error);
  })
}

