import React, { useState } from 'react';
import MerchantLayout from 'src/components/Layouts/MerchantLayout';
import { Stack, Grid, Box, Autocomplete, TextField } from '@mui/material';
import { NormalText } from 'src/components/styled/text.styled';
import { Button } from 'src/components/styled/button.styled';
import Tables from 'src/components/Table/tableData';
import {
  formatCurrency,
  getToken,
  objectToParams,
} from 'src/helper/generalFunction';
import InputDate from 'src/components/Inputs/DatePicker';
import useRedux from 'src/redux/useRedux';
import { getActiveBalance } from 'src/redux/transaction';
import moment from 'moment';
import { getAllStore, ListStoreData } from 'src/redux/storelist';
import { getWithdrawHistories, WithdrawResponse } from 'src/redux/withdraw';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import checkPermission from 'src/helper/checkPermission';

const WithdrawPage = () => {
  const {
    thunkDispatch,
    storeState: {
      Auth: {
        status,
        data: { user_type },
      },
      AppOption: { selected_store },
    },
  } = useRedux();
  const navigate = useNavigate();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [stores, setStores] = useState<ListStoreData[]>();
  const [histories, setHistories] = useState<WithdrawResponse>();
  const [balances, setBalances] = useState({ active: '', total_balance: '' });
  const [filterPayload, setFilterPayload] = React.useState<{
    start_date?: string;
    end_date?: string;
    store_id?: string;
  }>({
    start_date: undefined,
    end_date: undefined,
    store_id: undefined,
  });

  const fetchWithdraws = () => {
    setLoading(true);
    let storeId = filterPayload.store_id;
    if (
      status &&
      status === 'success' &&
      user_type &&
      user_type !== 'merchant'
    ) {
      storeId = String(
        selected_store && selected_store.id ? selected_store.id : ''
      );
    }
    thunkDispatch(
      getWithdrawHistories({
        limit: limit,
        offset: offset,
        store_id: storeId,
        start_date: filterPayload.start_date
          ? moment(filterPayload.start_date).format('YYYY-MM-DD')
          : '',
        end_date: filterPayload.end_date
          ? moment(filterPayload.end_date).format('YYYY-MM-DD')
          : '',
      })
    )
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') {
          setHistories(res.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleExport = async () => {
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = {
      'Content-Type': 'blob',
      api_key: `${process.env.REACT_APP_API_KEY}`,
      token: getToken(),
    };

    const params: {
      end_date?: string;
      start_date?: string;
      store_id?: string;
    } = {};

    if (filterPayload.end_date) {
      params.end_date = moment(filterPayload.end_date).format('YYYY-MM-DD');
    }
    if (filterPayload.start_date) {
      params.start_date = moment(filterPayload.start_date).format('YYYY-MM-DD');
    }
    if (filterPayload.store_id) {
      params.store_id = filterPayload.store_id;
    }
    const query = objectToParams(params);
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/merchant/finance/withdraw/download?${query}`,
      responseType: 'arraybuffer',
      headers,
    };

    try {
      const response = await axios(config);
      const outputFilename = `Laporan Withdraw-${moment().format(
        'DD MMMM YYYY'
      )}.xlsx`;

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      alert(error);
    }
  };

  React.useEffect(() => {
    let store_id = '';
    if (
      status &&
      status === 'success' &&
      user_type &&
      user_type !== 'merchant'
    ) {
      store_id = String(
        selected_store && selected_store.id ? selected_store.id : ''
      );
    } else {
      thunkDispatch(getAllStore({ offset: 0, limit: 0 }))
        .unwrap()
        .then((res) => {
          if (res && res.status === 'success') {
            setStores(res.data.data);
          }
        });
    }
    thunkDispatch(getActiveBalance({ store_id }))
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') {
          setBalances({
            active: res.data.data.active_balance,
            total_balance: res.data.data.total_active_balance,
          });
        }
      });
  }, [selected_store]);

  React.useEffect(() => {
    fetchWithdraws();
  }, [limit, offset, selected_store]);

  const columns = [
    {
      name: '#No Request',
      selector: (row: any) => row.request_number,
      sortable: true,
    },
    {
      name: 'Tanggal',
      selector: (row: any) => row.date,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row: any) => row.status,
      sortable: true,
    },
    {
      name: 'Total Penarikan',
      cell: (row: any) => formatCurrency(Number(row.amount)),
      sortable: false,
    },
    {
      name: 'Biaya Penarikan',
      cell: () => formatCurrency(Number(10000)),
      sortable: true,
    },
    {
      name: 'Total Diterima',
      cell: (row: any) => formatCurrency(Number(row.amount_received)),
      sortable: false,
    },
    {
      name: 'Toko',
      selector: (row: any) => row.store_name,
      sortable: true,
    },
    // {
    //   name: "User",
    //   selector: (row: any) => row.request_by,
    //   sortable: true,
    // },
  ];

  return (
    <MerchantLayout>
      <Box sx={{ background: 'white', borderRadius: '10px' }} padding={'20px'}>
        <NormalText fontSize="20px" fontWeight="bold" margin={'0 0 20px 0'}>
          Penarikan Dana
        </NormalText>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          margin={'0 0 20px 0'}
          alignItems={'center'}
        >
          <NormalText fontSize="20px" fontWeight="bold">
            Saldo Anda:{' '}
            {status &&
            status === 'success' &&
            user_type &&
            user_type === 'merchant'
              ? formatCurrency(Number(balances.total_balance ?? 0))
              : formatCurrency(Number(balances.active ?? 0))}
          </NormalText>
          {checkPermission('Withdraw', 'is_add') && (
            <Button
              variant="primary"
              background="#21a421 !important"
              onClick={() => navigate('/finance/withdraw/request')}
            >
              Tarik Dana
            </Button>
          )}
        </Stack>

        <Grid container>
          <Grid item md={2} xs={12} sm={12}>
            <NormalText fontSize="15px" fontWeight="bold">
              Pilih Tanggal
            </NormalText>
          </Grid>
          <Grid md={6}>
            <Stack direction={'row'} spacing={2} alignItems={'çenter'}>
              <InputDate
                value={filterPayload.start_date}
                disableFuture
                onChange={(v: any) =>
                  setFilterPayload({ ...filterPayload, start_date: v })
                }
              />
              <InputDate
                disableFuture
                value={filterPayload.end_date}
                onChange={(v: any) => {
                  if (filterPayload.start_date) {
                    if (moment(v).isAfter(filterPayload.start_date)) {
                      setFilterPayload({ ...filterPayload, end_date: v });
                    } else {
                      toast.warning(
                        'Tanggal akhir tidak boleh melebihi tanggal awal'
                      );
                    }
                  } else {
                    toast.warning(
                      'Silahkan pilih tanggal awal terlebih dahulu'
                    );
                  }
                }}
              />
            </Stack>
          </Grid>
        </Grid>

        {user_type && user_type === 'merchant' && (
          <Grid container margin={'20px 0 0 0'}>
            <Grid item xs={12} sm={12} md={2}>
              <NormalText fontSize="15px" fontWeight="bold">
                Pilih Toko
              </NormalText>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box width={'250px'}>
                <Autocomplete
                  id="combo-box-store-list"
                  options={stores && stores.length > 0 ? stores : []}
                  getOptionLabel={(option) => option.store_name || ''}
                  onChange={(event: any, value: any) =>
                    setFilterPayload({ ...filterPayload, store_id: value.id })
                  }
                  disableClearable
                  sx={{ width: '100%' }}
                  defaultChecked
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ fontSize: 12 }}
                      size="small"
                      placeholder="Pilih Toko"
                      value={filterPayload.store_id}
                      defaultValue={filterPayload.store_id}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
        )}

        <Grid container margin={'20px 0 0 0'}>
          <Grid item xs={12} sm={12} md={2}></Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Button variant="primary" onClick={() => fetchWithdraws()}>
              Filter
            </Button>
            <Button
              variant="primary"
              background="#21a421 !important"
              margin="0 0 0 10px"
              onClick={() => handleExport()}
            >
              Download Excel
            </Button>
          </Grid>
        </Grid>

        <Box
          sx={{
            background: 'rgba(13,202,240,.075)',
            padding: '1.25rem',
            marginTop: '20px',
            borderLeft: '0.25rem solid rgba(13,202,240,.5)',
          }}
        >
          <NormalText
            fontSize="1rem"
            fontWeight="400"
            wordBreak="break-word"
            color="#212529"
          >
            Dana transaksi yang berhasil akan masuk ke saldo Anda, sesuai dari
            settlement time masing-masing metode pembayaran. Waktu settlement
            bisa dilihat melalui link{' '}
            <a
              href="https://help.xendit.co/hc/en-us/articles/360038841432-What-is-the-settlement-time-for-each-payment-method-payment-channels"
              style={{ color: '#0d6efd', textDecoration: 'underline' }}
            >
              ini
            </a>
            . Untuk memindahkan saldo ke rekening, setelah saldo terisi Anda
            dapat menekan tombol Tarik Dana di kanan atas. Anda bisa menarik
            saldo langsung ke rekening kapanpun.
          </NormalText>
        </Box>

        <Tables
          hasContainer={false}
          records={histories && histories.data ? histories.data : []}
          columns={columns}
          isSearch={false}
          totalRow={
            histories && histories.meta && histories.meta.total
              ? histories.meta.total
              : 0
          }
          paginationPerPage={limit}
          progressPending={loading}
          onChangePage={(page) => setOffset(page - 1)}
          onChangeRowsPerPage={(currentRowsPerPage) =>
            setLimit(currentRowsPerPage)
          }
        />
      </Box>
    </MerchantLayout>
  );
};

export default WithdrawPage;
