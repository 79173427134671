import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import LoginPage from "./views/Auth/login";
import ChooseAccountPage from "./views/Auth/choose.account";
import ActivatedPage from "./views/Auth/activated";
import ResetPasswordPage from "./views/Auth/reset.password";
import DashboardPage from "./views/Dashboard/dashboard";
import RolesPage from "./views/Users/Role";
import AddRolePage from "./views/Users/Role/create";
import UpdateRolePage from "./views/Users/Role/update";
import ViewRolePage from "./views/Users/Role/view";
import StoreAccessPage from "./views/Users/StoreAccess";
import UserListPage from "./views/Users/User";
import AddUserPage from "./views/Users/User/create";
import ViewUserPage from "./views/Users/User/view";
import EditUserPage from "./views/Users/User/edit";
import CreatePasswordPage from "./views/Users/User/create.password";
import StoreList from "./views/Stores/Store/list";
import StoreForm from "./views/Stores/Store/form";
import UpdateStoreForm from "./views/Stores/Store/update";
import DetailStore from "./views/Stores/Store/view";
import GroupStoreForm from "./views/Stores/Store/createGroupStore";
import GroupStoreUpdateForm from "./views/Stores/Store/updateGroupStore";
import GroupStoreDetail from "./views/Stores/Store/viewGroupStore";
import TableTypeList from "./views/Stores/TableType/list";
import TableTypeForm from "./views/Stores/TableType/form";
import TableTypeUpdate from "./views/Stores/TableType/update";
import TableList from "./views/Stores/TableList/list";
import TableForm from "./views/Stores/TableList/form";
import TableUpdateForm from "./views/Stores/TableList/update";
import BrandsPage from "./views/Products/Brands";
import ProductList from "./views/Products/Product/list";
import ProductView from "./views/Products/Product/view";
import ProductForm from "./views/Products/Product/form";
import ProductStoreList from "./views/Products/ProductStore/list";
import AssignStoreProduct from "./views/Products/ProductStore/assign";
import DetailProductStore from "./views/Products/ProductStore/view";
import AddBrandsPage from "./views/Products/Brands/create";
import ViewBrandsPage from "./views/Products/Brands/view";
import EditBrandsPage from "./views/Products/Brands/create";
import CategoryPage from "./views/Products/Category";
import AddCategoryPage from "./views/Products/Category/create";
import AddSubCategoryPage from "./views/Products/Category/createSub";
import ViewSubCategoryPage from "./views/Products/Category/viewSub";
import ViewCategoryPage from "./views/Products/Category/view";
import SequencePage from "./views/Products/Category/sequence";
import ProductUpdate from "./views/Products/Product/update";
import ProductAddOnList from "./views/Products/ProductAddOn/list";
import AssignProductAddOn from "./views/Products/ProductAddOn/assign";
import ViewProductAddOn from "./views/Products/ProductAddOn/view";
import BannerPage from "./views/Informasi/Banner";
import AddBannerPage from "./views/Informasi/Banner/create";
import ViewBannerPage from "./views/Informasi/Banner/view";
import EditBannerPage from "./views/Informasi/Banner/create";
import PickupPointPage from "./views/Stores/PickupPoint";
import CreatePickupPointPage from "./views/Stores/PickupPoint/create";
import ViewPickupPointPage from "./views/Stores/PickupPoint/view";
import UpdatePickupPointPage from "./views/Stores/PickupPoint/create";
import AppSettingPage from "./views/Setting/appSetting";
//import FeeSettingPage from "./views/Setting/feeSetting";
import VouchersPage from "./views/Products/Voucher";
import CreateVoucherPage from "./views/Products/Voucher/create";
import UpdateVoucherPage from "./views/Products/Voucher/edit";
import DetailVoucherPage from "./views/Products/Voucher/view";

// Finances
import BankPage from "./views/Finance/Bank/bank.page";
import WithdrawPage from "./views/Finance/Withdraw/withdraw.page";
//setup PUP
import PickupPointSetupPage from "./views/Stores/PickupPointSetup";
import CreatePickupPointSetupPage from "./views/Stores/PickupPointSetup/create";
import ViewPickupPointSetupPage from "./views/Stores/PickupPointSetup/view";
import UpdatePickupPointSetupPage from "./views/Stores/PickupPointSetup/create";
import NotFound from "./views/NotFound";

//orders
import NewOrders from "./views/Order/newOrder";
import PendingOrders from "./views/Order/pending";
import ProcesOrders from "./views/Order/procesOrder";
import ReadyOrders from "./views/Order/readyOrder";
import SendOrders from "./views/Order/sendOrder";
import CompleteOrders from "./views/Order/completeOrder";
import CancelOrders from "./views/Order/cancelOrder";
// Report
import TransactionReportPage from "./views/Report/Transaction/transaction.page";
import OrderReportPage from "./views/Report/Order/order.page";
import ClosingKasirPage from "./views/Report/ClosingKasir/closingKasir.page";

//store access
import StoreAccessAddPage from "./views/Users/StoreAccess/create";

//loyalty
import LoyaltyPageList from "./views/Setting/loyalty";
import LoyaltyPageCreate from "./views/Setting/loyalty/create";
import RequestWithdrawPage from "./views/Finance/Withdraw/request.page";
import CustomerListPage from "./views/Customer/list.page";

//delivery
import DeliveryPageList from "./views/Setting/delivery";
import DeliveryPageCreate from "./views/Setting/delivery/create";
// import CustomerListPage from "./views/Customer/list.page";

//profil
import ProfilUser from "./views/Profile/User/Profil/index";
import UbahSandiUser from "./views/Profile/User/ResetPassword/index";
import ProfilMerchant from "./views/Profile/Merchant/Profil/index";
import UbahSandiMerchant from "./views/Profile/Merchant/ResetPassword/index";

//help
import HelpPage from "./views/Help/index";

//Kasir
import SesiKasirPage from "./views/Kasir/SesiKasir";
import DetailOrder from "./views/Order/detail";

//notifications
import AllNotifications from "./views/Notifications";

// const RouteGuard = (element, path) => {
//   if (checkPermision()) {
//     return <Route />;
//   }else {
//     <Route forbidden route/>
//   }
// };

import { useState, useEffect } from "react";
import { fetchToken, onMessageListener } from "./firebase";
import { toast } from "react-toastify";
import useRedux from "src/redux/useRedux";
import Sound from "src/assets/media/audio/notif.mp3";
import { showDetailStore } from "src/redux/storelist";
import { updateNotification } from "src/redux/notification/notification.reducer";

const App = () => {
  //using firebase
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const {
    thunkDispatch,
    storeState: {
      Auth,
      AppOption,
      Notification: { notifications },
    },
  } = useRedux();
  const getDetailStore = async (id: any) => {
    await thunkDispatch(showDetailStore(id ? Number(id) : 0))
      .unwrap()
      .then((res: any) => {
        if (res && res.status === "success") {
          let dataDetail = res.data.data;
          sessionStorage.setItem("store_code", dataDetail.store_code);
          fetchToken(dataDetail.store_code, "SUBSCRIBE");
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    //get data from session to unset topic
    let store_code = sessionStorage.getItem("store_code");
    //unsubscribe topic when stay at login page
    if (store_code && location.pathname === "/login") {
      fetchToken(store_code, "UNSUBSCRIBE");
      sessionStorage.clear();
    }

    if (Auth && Auth.data && Auth.data.user_type === "user_merchant") {
      if (
        AppOption &&
        AppOption.selected_store &&
        AppOption.selected_store.id
      ) {
        if (store_code) {
          fetchToken(store_code, "UNSUBSCRIBE");
        }
        //
        getDetailStore(AppOption.selected_store.id);
      }
    }
  }, [Auth, AppOption]);

  useEffect(() => {
    onMessageListener()
      .then((payload) => {
        setNotification({
          title: payload.notification.title,
          body: payload.notification.body,
        });
        setShow(true);
        console.log(payload);
        //play sound and show toast
        new Audio(Sound).play();
        toast.success(
          `${payload.notification.title}. Untuk lebih detailnya silahkan klik icon lonceng .`
        );
        //update state notification when get message from firebase
        let prop: { count_notif: number } = {
          count_notif: notifications.count_notif
            ? notifications.count_notif + 1
            : 1,
        };
        thunkDispatch(updateNotification(prop));
      })
      .catch((err) => console.log("failed: ", err));
  });

  return (
    <Routes>
      <Route path="*" element={<Navigate to="/404" replace />} />
      <Route path="/" element={<DashboardPage />} />
      <Route path="/choose-account" element={<ChooseAccountPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/activation" element={<ActivatedPage />} />
      <Route path="/password-recovery/:key" element={<ResetPasswordPage />} />
      <Route path="/users/list" element={<UserListPage />} />
      <Route path="/users/list/add-user" element={<AddUserPage />} />
      <Route path="/users/roles" element={<RolesPage />} />
      <Route path="/users/roles/add-role" element={<AddRolePage />} />
      <Route path="/users/roles/:id/update" element={<UpdateRolePage />} />
      <Route path="/users/roles/:id/view" element={<ViewRolePage />} />
      <Route path="/users/store-access" element={<StoreAccessPage />} />
      <Route path="/users/list/view-user" element={<ViewUserPage />} />
      <Route path="/users/list/edit-user" element={<EditUserPage />} />
      <Route path="/account-activation/:key" element={<CreatePasswordPage />} />
      <Route path="/stores/store/list" element={<StoreList />} />
      <Route path="/stores/store/form" element={<StoreForm />} />
      <Route path="/stores/store/view/:id" element={<DetailStore />} />
      <Route path="/stores/store/:id/update" element={<UpdateStoreForm />} />
      <Route path="/stores/group-store/create" element={<GroupStoreForm />} />
      <Route
        path="/stores/group-store/:id/update"
        element={<GroupStoreUpdateForm />}
      />
      <Route
        path="/stores/group-store/:id/view"
        element={<GroupStoreDetail />}
      />
      <Route path="/stores/tabletype/list" element={<TableTypeList />} />
      <Route path="/stores/tabletype/form" element={<TableTypeForm />} />
      <Route
        path="/stores/tabletype/:id/update"
        element={<TableTypeUpdate />}
      />
      <Route path="/stores/table/list" element={<TableList />} />
      <Route path="/stores/table/form" element={<TableForm />} />
      <Route path="/stores/table/:id/update" element={<TableUpdateForm />} />
      <Route path="/products/product/list" element={<ProductList />} />
      <Route path="/products/product/form" element={<ProductForm />} />
      <Route path="/products/product/view/:id" element={<ProductView />} />
      <Route path="/products/product/:id/update" element={<ProductUpdate />} />
      <Route path="/products/brands" element={<BrandsPage />} />
      <Route path="/products/brands/add-brands" element={<AddBrandsPage />} />
      <Route path="/products/brands/view-brands" element={<ViewBrandsPage />} />
      <Route path="/products/brands/edit-brands" element={<EditBrandsPage />} />
      <Route path="/products/categories" element={<CategoryPage />} />
      <Route
        path="/products/categories/add-categories"
        element={<AddCategoryPage />}
      />
      <Route
        path="/products/categories/edit-categories"
        element={<AddCategoryPage />}
      />
      <Route
        path="/products/categories/add-sub-categories"
        element={<AddSubCategoryPage />}
      />
      <Route
        path="/products/categories/edit-sub-categories"
        element={<AddSubCategoryPage />}
      />
      <Route
        path="/products/categories/view-sub-categories"
        element={<ViewSubCategoryPage />}
      />
      <Route
        path="/products/categories/view-categories"
        element={<ViewCategoryPage />}
      />
      <Route path="/products/categories/sequence" element={<SequencePage />} />
      <Route path="/products/store-product" element={<ProductStoreList />} />
      <Route
        path="/products/store-product/:key/assign"
        element={<AssignStoreProduct />}
      />
      <Route
        path="/products/store-product/:key/view"
        element={<DetailProductStore />}
      />
      <Route path="/products/product-addon" element={<ProductAddOnList />} />
      <Route
        path="/products/product-addon/:key/assign"
        element={<AssignProductAddOn />}
      />
      <Route
        path="/products/product-addon/:key/view"
        element={<ViewProductAddOn />}
      />
      <Route path="/products/vouchers" element={<VouchersPage />} />
      <Route path="/products/vouchers/create" element={<CreateVoucherPage />} />
      <Route
        path="/products/vouchers/:id/update"
        element={<UpdateVoucherPage />}
      />
      <Route
        path="/products/vouchers/:id/view"
        element={<DetailVoucherPage />}
      />
      <Route path="/informasi/banner" element={<BannerPage />} />
      <Route path="/informasi/banner/add-banner" element={<AddBannerPage />} />
      <Route
        path="/informasi/banner/view-banner"
        element={<ViewBannerPage />}
      />
      <Route
        path="/informasi/banner/edit-banner"
        element={<EditBannerPage />}
      />
      {/* PickupPoint */}
      <Route
        path="/stores/pickup-point-list/list"
        element={<PickupPointPage />}
      />
      <Route
        path="/stores/pickup-point-list/add-pickup-point"
        element={<CreatePickupPointPage />}
      />
      <Route
        path="/stores/pickup-point-list/view-pickup-point"
        element={<ViewPickupPointPage />}
      />
      <Route
        path="/stores/pickup-point-list/edit-pickup-point"
        element={<UpdatePickupPointPage />}
      />
      <Route path="/setting/appsetting" element={<AppSettingPage />} />
      {/* <Route path="/setting/feesetting" element={<FeeSettingPage />} /> */}
      {/* Customer */}
      <Route path="/customer/list" element={<CustomerListPage />} />
      {/* pup setup */}
      <Route
        path="/stores/pickup-point-setup/list"
        element={<PickupPointSetupPage />}
      />
      <Route
        path="/stores/pickup-point-setup/add-pickup-point"
        element={<CreatePickupPointSetupPage />}
      />
      <Route
        path="/stores/pickup-point-setup/view-pickup-point"
        element={<ViewPickupPointSetupPage />}
      />
      <Route
        path="/stores/pickup-point-setup/edit-pickup-point"
        element={<UpdatePickupPointSetupPage />}
      />
      {/* route orders */}
      <Route path="/orders/pending" element={<PendingOrders />} />
      <Route path="/orders/new" element={<NewOrders />} />
      <Route path="/orders/process" element={<ProcesOrders />} />
      <Route path="/orders/ready" element={<ReadyOrders />} />
      <Route path="/orders/send" element={<SendOrders />} />
      <Route path="/orders/complete" element={<CompleteOrders />} />
      <Route path="/orders/cancel" element={<CancelOrders />} />
      <Route path="/orders/:code/detail" element={<DetailOrder />} />
      {/* Finances Route */}
      <Route path="/finance/bank" element={<BankPage />} />
      <Route path="/finance/withdraw" element={<WithdrawPage />} />
      <Route
        path="/finance/withdraw/request"
        element={<RequestWithdrawPage />}
      />
      {/* Report Router */}
      <Route path="/report/transaction" element={<TransactionReportPage />} />
      <Route path="/report/order" element={<OrderReportPage />} />
      <Route path="/report/closing-kasir" element={<ClosingKasirPage />} />

      {/* store akses */}
      <Route path="/users/store-access/add" element={<StoreAccessAddPage />} />
      {/* loyalty */}
      <Route path="/setting/loyaltysetting" element={<LoyaltyPageList />} />
      <Route
        path="/setting/loyaltysetting/create"
        element={<LoyaltyPageCreate />}
      />
      <Route
        path="/setting/loyaltysetting/edit"
        element={<LoyaltyPageCreate />}
      />
      {/* delivery */}
      <Route path="/setting/deliverysetting" element={<DeliveryPageList />} />
      <Route
        path="/setting/deliverysetting/create"
        element={<DeliveryPageCreate />}
      />
      <Route
        path="/setting/deliverysetting/edit"
        element={<DeliveryPageCreate />}
      />
      {/* profil */}
      <Route path="/profile/user/profil" element={<ProfilUser />} />
      <Route path="/profile/user/change-password" element={<UbahSandiUser />} />
      <Route path="/profile/merchant/profil" element={<ProfilMerchant />} />
      <Route
        path="/profile/merchant/change-password"
        element={<UbahSandiMerchant />}
      />
      {/* notification */}
      <Route path="/notifications" element={<AllNotifications />} />
      {/* Help */}
      <Route path="/help" element={<HelpPage />} />
      {/* 404 Page */}
      <Route path="/404" element={<NotFound />} />
      {/* Sesi Kasir Page */}
      <Route path="/kasir/sesi-kasir" element={<SesiKasirPage />} />
    </Routes>
  );
};

export default App;
